<template>
  <div class="stemcells">
    <div class="cells-container">
     <!-- <Worktrends :worklist="workinfolist" :collectinfo="Statisticslist"></Worktrends>
     <Scintroduct :Scintroduct="Scintroduct"></Scintroduct>
     <Donatetoknow :donatetoknow="donatetoknow"></Donatetoknow> -->
     <!-- <Remembrance :remembrancelist="drystyle" :title="drystyletitle"></Remembrance> -->
	 <ul class="newslist">
		 <!--<li v-for="(item) in drystyle" :key="item.id" @click="goDetail(item.id)">-->
	   <li v-for="(item) in drystyle" :key="item.id" >
	     <dt>
	       <img style="width:100%; height:100%" :src="item.smallImage" alt="">
	     </dt>
	     <dd>
	       <h2>{{item.title}}</h2>
	       <!-- <p>
	         中国红十字会顺应社会需要、践行自身宗旨、接受政府委托、
	         依据法律授权逐步参与遗体和人体器官捐赠，作出了积极的贡献。
	         2007年3月国务院《人体器官移植条例》颁布，2010年3月中国红十字会
	        </p> -->
	        <p>{{item.metaDescription}}</p>
	     </dd>
	   </li>
	 </ul>
	  <el-pagination
	  @current-change="handleCurrentChange"
	  :current-page="page"
	  :page-size="size"
	  layout="total, sizes, prev, pager, next, jumper"
	  :total="total">
	  </el-pagination>
     <!-- <Popular :getpopularlist="popularlist" :propulartitle="'造干科普'"></Popular> -->
    </div>
  </div>
</template>

<script>
// import Worktrends from './components/worktrends'
// import Scintroduct from '../Bodydonate/components/scintroduct'
// import Donatetoknow from './components/donatetoknow'
import Remembrance from '../Bodydonate/components/remembrance'
// import Popular from '../Bodydonate/components/popular'
import apiUrls from '@api'
export default {
  components : {
    //   Worktrends,
    //   Scintroduct,
    //   Donatetoknow,
      Remembrance,
    //   Popular
  },
  // name: 'Stemcells',
  name: 'Projectnews',
  data () {
    return {
      workinfolist: [],
      Statisticslist: {},
      drystyle: [],
      drystyletitle: "造干志愿者风采",
      popularlist: [],
      Scintroduct : {},
      donatetoknow: {},
	  page: 1,
	  size: 10,
	  total: 0
    }
  },
  created () {
    // this.getworklist()
    // this.getStatistics()
    // this.getScintroduct()
    // this.getdonatetoknow()
    // this.getdrystyle()
	this.handleCurrentChange()
    // this.getpopular()
  },
  methods : {
     /* getworklist() {
      apiUrls.getstemcell({node: 'gxb_gzdt'}).then(res=>{

      this.workinfolist= res.results.data
      })
    },
    getStatistics() {
      apiUrls.getStatistics({node: 'zxgxbjcxx'}).then(res=>{

      this.Statisticslist= res.results.data
      })
    },
    getScintroduct() {
      apiUrls.getStatistics({node: 'gxbscsjcqkjj'}).then(res => {

        this.Scintroduct = res.results.data
      })
    },
    getdonatetoknow() {
      apiUrls.getStatistics({node: 'gxbjxxz'}).then(res => {

        this.donatetoknow = res.results.data
      })
    }, */
  /* getdrystyle() {
      apiUrls.getstemcell({node: 'zgzyzfc'}).then(res=>{

      this.drystyle= res.results.data
      })
    }, */
	handleCurrentChange(val) {
	  apiUrls.getstemcell({node: 'zgzyzfc', page: val===1?0:val-1}).then(res=>{
	  this.drystyle= res.results.data
	  this.total = res.results.pager.totalElements
	  })
	},
	goDetail(id){
	  this.$router.push({path:'/detail2',query:{id}})
	}
   /* getpopular() {
      apiUrls.getstemcell({node: 'zgxb_kpzs'}).then(res=>{

      this.popularlist= res.results.data
      })
    } */
  }
}
</script>

<style lang="less" scoped>
.stemcells {
  background-color: #f5f5f5;
  .cells-container {
    width: 1200px;
    min-height: 400px;
    padding-bottom: 40px;
    margin: 0 auto;
    background-color: #f5f5f5;

    &::before {
        display: block;
        content: ".";
        clear: both;
        height: 0;
        overflow: hidden;
        visibility: hidden; 
    }
  }
}
.newslist {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      li {
		  &:hover {
		  		  transform: translateY(-5px);
		  		  box-shadow: 5px 5px 5px #ccc;
		  }
        width: 578px;
        height: 218px; 
        box-shadow: 0px 0px 10px 0px rgba(58, 58, 58, 0.24);
        border-radius: 8px;
        display: flex;
        margin-bottom: 18px;
        dt {
          width: 256px;
          height: 200px;
          background-color: #666666;
          margin: 8px 14px 10px 10px;
        }
        dd {
          width: 287px;
          height: 200px;
          // border: 1px solid #666666;
          h2 {
            text-align: center;
            font-size: 18px;
            font-weight: bolder;
            color: #D72829;
            margin-top: 26px;
            margin-bottom: 23px;
          }
          p {
            width: 287px;
            height: 129px;
            font-size: 16px;
            color: #666666;
            line-height: 26px;
            text-indent: 2em;
            overflow: hidden;
          }
        }
      }
    }
</style>
<style>
.el-pagination {
    min-width: 616px;
    margin: 0 auto;
    text-align: center;
    margin-top: 14px;
}
.el-pager li.active {
    color: #D1292E !important;
 }
 .el-pager li:hover {
   color: #D1292E !important;
 }
</style>
